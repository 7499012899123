const Snowflakes = () => {
    return (
      <div>
        {[...Array(50)].map((_, i) => (
          <div className="snowflake" key={i} style={{ left: `${Math.random() * 100}vw` }}></div>
        ))}
      </div>
    );
  };
  
  export default Snowflakes;
  